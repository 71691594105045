import * as React from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Container from '../components/Container'
import { Category } from '../components/Category'
import PageHeader from '../components/PageHeader'
import { breakpoints } from '../styles/variables'

const ListContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
  }
`

const StyledListPage = styled(Page)`
  padding: 0;
`

const ListPage = (props: any) => {
  const urlParameter = props['*']

  console.log('Our url parameter is', urlParameter)

  let typeParam = ''
  let titleParam = ''

  switch (urlParameter) {
    case 'uusimmat': {
      typeParam = 'list-latest'
      titleParam = 'Uusimmat'
      break
    }
    case 'suosituimmat': {
      typeParam = 'list-recommended'
      titleParam = 'Suosituimmat'
      break
    }
    default: {
      console.log(urlParameter.charAt(0))
      typeParam = 'list-category'
      titleParam = urlParameter
    }
  }
  const renderCategories = () => {
    if (urlParameter.endsWith('-videos')) {
      return (
        <ListContainer>
          <PageHeader title={titleParam.slice(0, -10)} />
          <Category type={`${typeParam}-files-videos`} title={titleParam} screenTitle="Uusimpia jaksoja teemasta" description="" />
          <Category type={`${typeParam}-series-videos`} title={titleParam} screenTitle="Teeman tarjonta" description="" categoryIndex={1} />
        </ListContainer>
      )
    } else
      return (
        <ListContainer>
          <PageHeader title={titleParam} />
          <Category type={`${typeParam}-files`} title={titleParam} screenTitle="Uusimpia jaksoja teemasta" description="" />
          <Category type={`${typeParam}-series`} title={titleParam} screenTitle="Teeman tarjonta" description="" categoryIndex={1} />
        </ListContainer>
      )
  }
  return <StyledListPage>{renderCategories()}</StyledListPage>
}

export default ListPage
